import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import realEstatePath from "../../Assets/Projects/real-estate.png";
import ficciPath from "../../Assets/Projects/ficci.png";
import tenderPath from "../../Assets/Projects/tender.png";
import bbPath from "../../Assets/Projects/bb.png";
import dcciPath from "../../Assets/Projects/dcci.png";
import eccubePath from "../../Assets/Projects/eccube.png";
import edcPath from "../../Assets/Projects/edc.png";

function Projects() {

    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    My Recent <strong className="purple">Works </strong>
                </h1>
                <p style={{ color: "white" }}>
                    Here are a few projects I've worked on recently.
                </p>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={eccubePath}
                            isBlog={false}
                            technologies={[
                                { badge_name: "primary", text: 'Symfony' },
                                { badge_name: "danger", text: 'eccube4' },
                                { badge_name: "success", text: 'MySql' },
                                { badge_name: "danger", text: 'Bootstrap' },
                            ]}
                            title="EC-CUBE (JP E-commerce website and Party membership management application)"
                            description="EC-CUBE is the most popular e-commerce open source in Japan.
                             I have experienced to work Japanies project. We have modified ec-cube for client purpose. 
                             Two developer work in this project. I am sharing little bit story. This project is called Sansaito. 
                             Here they sell there products customer wise. Products price is vary customer party type wise.
                              party type registration process and have payment. My senior collect requirment and provide me.
                             He helpe me for payment getway integration which is custome GMOPlugine. Previously i have no experience 
                             to worked with symfony eccube. Lots of test case pass successfully. 
                             "
                            ghLink="https://www.sanseito.jp"
                            demoLink="https://www.sanseito.jp"
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={edcPath}
                            isBlog={false}
                            technologies={[
                                { badge_name: "primary", text: 'PHP' },
                                { badge_name: "danger", text: 'Laravel' },
                                { badge_name: "success", text: 'MySql' },
                                { badge_name: "danger", text: 'Bootstrap' },
                            ]}
                            title="EDC Payroll (HRM) ICT Division:"
                            description="This is payroll application for ICT division. This was our existing project but 
                            it was a generic application. Lots of thing need to update. My senior collect requirments. 
                            Me and my one junior implement it. Branch manage, 
                            Dinamically holiday (Weekly, Other) with calender manage. Recruitment manage (job create,job on board, Employee seection). 
                            Organogram for office and employee.Employee grade wise salary, 
                            allowance, deduction set, payslip generate. 
                            Attendence manage. Performance manage, Employee training manage.
                             "
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={tenderPath}
                            isBlog={false}
                            technologies={[
                                { badge_name: "primary", text: 'PHP' },
                                { badge_name: "danger", text: 'Laravel' },
                                { badge_name: "success", text: 'MySql' },
                                { badge_name: "success", text: 'Vuejs' },
                                { badge_name: "danger", text: 'Bootstrap' },
                            ]}
                            title="Tender management for System Engineering Ltd."
                            description="I was team leader in this application. This is a brand new application that we made from scratch. 
                            First our ui/ux team create desing structure for admin panel. We collect requirments and mange it Azure DevOps.
                            We divided this application into two part. Front end and back end. Vuejs select for frontend and backend for laravel.
                            Tender create, Submission, Award and ececution,Bill submission, bill receive, Performance security, bank guarantee, tender security. contact awared, 
                            failure is the key feature in this application. At a glance we can say tender tracking system."
                            ghLink="http://tender.selbn.com/"
                            demoLink="http://tender.selbn.com/"
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={realEstatePath}
                            isBlog={false}
                            technologies={[
                                { badge_name: 'primary', text: 'PHP' },
                                { badge_name: 'danger', text: 'Laravel' },
                                { badge_name: 'info', text: 'Javascript' },
                                { badge_name: 'danger', text: 'jQuery' },
                                { badge_name: 'success', text: 'MySql' },
                            ]}
                            title="Property Management with Complete Accounts"
                            description="This software simplifies project and financial management.
                        It handles single or multiple projects, real estate products, and sales transactions.
                        Manage schedules, purchase requisitions, and orders.
                        Maintain a flexible chart of accounts with dynamic ledger entries.
                        Automatic report generation and user transaction logs keep your finances in check.
                        Get financial insights with trial balances, profit and loss statements,
                        and cash flow reports. Efficiently manage user roles and permissions for seamless operation."
                            ghLink="https://demo.erealestate.eaccount.xyz/"
                            demoLink="https://demo.erealestate.eaccount.xyz"
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={ficciPath}
                            isBlog={false}
                            technologies={[
                                { badge_name: "primary", text: 'PHP' },
                                { badge_name: "danger", text: 'Laravel' },
                                { badge_name: "success", text: 'jQuery' },
                                { badge_name: "danger", text: 'Bootstrap' },
                                { badge_name: "success", text: 'MySql' },
                            ]}
                            title="Membership Management for FICCI (Foreign Investors' Chamber Of Commerce & Industry)"
                            description="This is website and membership management application. Customer can registration
                            and request for membership process. After approval member can digital certificate. Certificate
                            verify in this application. Member can some service like Coo (Country of origin), Vr (Visa referal)
                            . Renewal process are generate autometically. Online payment getwaya is integrated. At least a 
                            complete membership process with website manage on this application."
                            ghLink="https://ficci.org.bd/"
                            demoLink="https://ficci.org.bd/"
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={dcciPath}
                            isBlog={false}
                            technologies={[
                                { badge_name: 'primary', text: 'PHP' },
                                { badge_name: 'danger', text: 'Laravel' },
                                { badge_name: 'info', text: 'Javascript' },
                                { badge_name: 'danger', text: 'jQuery' },
                                { badge_name: 'success', text: 'MySql' },
                            ]}
                            title="Membership management for DCCI (Dhaka Chamber of Commerce & Industry)"
                            description="When I joined SDL membership management already developed by codeIgniter 
                            which is manage by only DCCI staff not member. They want membership for portal.
                            I work here by junior developer. User can self registration 
                            and request for membership. After approval member can take service and certificate by there admin panel. 
                            Auto renewal process and lots of stuff are doing using this application."
                            ghLink="https://demo.erealestate.eaccount.xyz/"
                            demoLink="https://demo.erealestate.eaccount.xyz"
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={bbPath}
                            isBlog={false}
                            technologies={[
                                { badge_name: "primary", text: 'PHP' },
                                { badge_name: "danger", text: 'Laravel' },
                                { badge_name: "success", text: 'MySql' },
                                { badge_name: "danger", text: 'Bootstrap' },
                            ]}
                            title="Brand Bangladesh (E-commerce):"
                            description="This is a E-commerce website. User can purchase product from this application. 
                            Number of purchase quentity offer can generate. Variant and color wise product can set."
                            ghLink="https://www.brandbangladesh.com/"
                            demoLink="https://www.brandbangladesh.com/"
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Projects;
