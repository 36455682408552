import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

function ProjectCards(props) {
    const badgeStyle = {
        justifyContent: 'center'
    };
    return (
        <Card className="project-card-view">
            <a target="_blank" href={props.imgPath}><Card.Img variant="top" src={props.imgPath} alt="card-img" /></a>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <p>Technology uses: </p>
                <Stack style={badgeStyle} direction="horizontal" gap={2}>
                    {props.technologies.map((item) => (
                        <Badge bg={item.badge_name}>{item.text}</Badge>
                    ))}
                </Stack>
                <br />
                <Card.Text style={{ textAlign: "justify" }}>
                    {props.description}
                </Card.Text>
                {"\n"}
                {"\n"}
                {!props.isBlog && props.demoLink && (
                    <Button
                        variant="primary"
                        href={props.demoLink}
                        target="_blank"
                        style={{ marginLeft: "10px" }}
                    >
                        <CgWebsite /> &nbsp;
                        {"URl"}
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
}
export default ProjectCards;
